<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{ collapsed: !visible , 'bg-light-warning': selectedTrip && selectedTrip.id === item.id || visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      class="px-0 py-0"
      role="tab"
      data-toggle="collapseflight"
      @click="updateVisible(!visible)"
    >
      <b-row
        :class="`w-100 d-flex justify-content-around flex-lg-nowrap`"
        style="max-height: 55px;"
        no-gutters
      >
        <!-- ANCHOR - 1 Logo -->
        <b-col
          class="d-flex-center"
          cols="2"
          md="1"
        >
          <div
            :style="`${['1G', 'F1', 'AK', 'TH'].includes(item.source) ? `position: relative; box-sizing: border-box; overflow: hidden; width: 100%;` : ''} `"
          >
            <div
              :class="`d-flex-center ml-1 ${resolveBadgeSource(item.source, item?.contentSource)}`"
            >
              <IAmLogoAirline
                :airline="item.segments[0].operating === 'BL' ? item.segments[0].operating : (item.airline.split(' | ')[0] || item.operator)"
                :height="40"
              />
            </div>
          </div>
        </b-col>

        <!-- ANCHOR - 2 Số hiệu -->
        <b-col
          class="d-flex flex-column justify-content-center align-items-center"
          cols="1"
          xl="1"
        >
          <div
            v-b-tooltip.hover.html.v-primary
            class="font-weight-bolder align-center"
            :title="item.segments.map(item => resolveAirlineFlightNumber(item.airline, item.flightNumber)).join(' | ')"
          >
            <span
              class="text-airline text-nowrap fw-700"
              :class="isMobileView ? '' : 'font-medium-2'"
            >
              {{ resolveAirlineFlightNumber(item.segments[0].airline, item.segments[0].flightNumber) }}
            </span>
          </div>

          <!-- ANCHOR - Tên máy bay - Mobile -->
          <div
            v-if="isMobileView"
            class="text-center"
          >
            <span
              v-b-tooltip.hover.html.v-primary
              class="text-nowrap text-body fw-700"
              :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
            >
              {{ convertShortenAircraft(item.segments[0].airCraft) }}
            </span>
          </div>
        </b-col>

        <!-- ANCHOR - 3 Thời gian bắt đầu - kết thúc -->
        <b-col
          md="2"
          class="d-none d-md-flex align-items-center justify-content-center text-black text-center"
        >
          <span
            v-b-tooltip.hover.html.v-primary.top.window
            :class="['xs'].includes(appBreakPoint) ? 'font-small-1' : 'font-medium-1 text-nowrap'"
            :title="`${$t('flight.totalDuration')}: ${toHoursAndMinutes(item.duration)}`"
          >
            {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
          </span>
        </b-col>

        <!-- ANCHOR - 4 Điểm bắt đầu - điểm dừng - điểm kết thúc -->
        <b-col
          cols="5"
          md="3"
          class="text-center d-flex flex-column justify-content-center align-items-center"
          :class="isMobileView ? '' : 'font-medium-2'"
        >
          <div class="text-nowrap">
            <span
              v-for="(point, pointIndex) in getTripNameBySegments(item)"
              :key="pointIndex"
            >
              <span
                v-b-tooltip.hover.html.v-primary.top.window
                :class="pointIndex !== 0 && pointIndex !== getTripNameBySegments(item).length - 1 ? `font-weight-light ${isMobileView ? 'font-small-2' : 'font-medium-1'}` : 'fw-700'"
                :title="isMobileView ? '' : `<div>${pointIndex !== 0 && pointIndex !== getTripNameBySegments(item).length - 1 ? 'Điểm dừng:' : ''} ${point.name}</div>
                    ${point.transitTime ? `<div>${$t('ticket.flightsInfo.transitTime')}: ${point.transitTime}</div>` : ''}`"
              >
                {{ point.iataCode }}
              </span>
              <span v-if="pointIndex !== getTripNameBySegments(item).length - 1"> - </span>
            </span>
          </div>

          <div
            v-if="isMobileView"
            class="font-weight-bold"
          >
            {{ `${convertISODateTime(item.departure.at, item.departure.timezone).time} - ${convertISODateTime(item.arrival.at, item.arrival.timezone).time}` }}
          </div>
        </b-col>

        <!-- ANCHOR - 5 Tên máy bay -->
        <b-col
          class="d-none d-md-flex align-items-center"
          cols="1"
        >
          <span
            v-b-tooltip.hover.html.v-primary
            class="text-nowrap font-weight-bold"
            :class="isMobileView ? '' : 'font-medium-2'"
            :title="getAirCraftByIata(item.segments[0].airCraft) || item.segments[0].airCraft"
          >
            {{ convertShortenAircraft(item.segments[0].airCraft) }}
          </span>
        </b-col>

        <!-- ANCHOR - 6 Hạng vé -->
        <!-- <b-col
          cols="1"
          class="text-warning font-weight-bold text-left d-none d-md-block text-truncate"
          :class="isMobileView ? '' : 'font-medium-2'"
        >
          <div class="d-flex-center">
            <template v-if="['TAP'].includes(item.clientId) && ['VN1A'].includes(item.source) && !item.domestic">
              <b-img
                style="height: 24px;"
                :src="require(`@icons/luggage.svg`)"
              />
            </template>
            <template v-else>
              <div v-if="getMinFareOptions(item.fareOptions).luggage !== null">
                <b-img
                  v-if="getMinFareOptions(item.fareOptions).luggage"
                  style="height: 24px;"
                  :src="require(`@icons/luggage.svg`)"
                />
                <b-img
                  v-else
                  style="height: 24px;"
                  :src="require(`@icons/no-luggage.svg`)"
                />
              </div>
            </template>
            <span
              v-b-tooltip.hover.html.v-primary.top.window
              :title="getMinFareOptions(item.fareOptions).groupClassName"
            >
              {{ getMinFareOptions(item.fareOptions).bookingClassAndSeatAvail }}
            </span>
          </div>
        </b-col> -->

        <!-- ANCHOR - 7 Giá vé -->
        <b-col
          v-if="77 + 33 === 100"
          class="d-flex flex-column align-items-start justify-content-center"
          cols="2"
        >
          <div v-if="!getMinFareOptions(item.fareOptions).price">
            <!-- Hết chỗ -->
            <span
              id="flight.full"
              class="text-danger"
            >
              {{ $t('flight.full') }}
            </span>
          </div>
          <div v-else>
            <div
              v-if="getShowPrice !== 'HIDE_FARE'"
              :class="selectedTrip && selectedTrip.fareOptions && selectedTrip.fareOptions.id === getMinFareOptions(item.fareOptions).id ? 'text-success' : 'text-warning'"
            >
              <div
                :class="`text-danger fw-600 d-flex-center
                    ${isMobileView ? ' font-small-4' : 'font-medium-2'}`"
              >
                {{ formatCurrency(getMinFareOptions(item.fareOptions).price) }}
              </div>
            </div>

            <div class="d-flex-center">
              <del
                v-if="getMinFareOptions(item.fareOptions).discount && getShowPrice !== 'HIDE_FARE'"
                class="text-secondary font-weight-bolder position-relative"
                :class="isMobileView ? 'font-small-2' : 'font-small-4'"
              >
                <span>{{ formatCurrency(getMinFareOptions(item.fareOptions).price + getMinFareOptions(item.fareOptions).discount) }}</span>
              </del>
            </div>

            <div
              v-if="isMobileView"
              class="d-flex-center"
            >
              <div
                v-if="getShowBookingClass"
                cols="1"
                class="d-flex-center justify-content-end text-warning font-weight-bold"
              >
                <template v-if="['TAP'].includes(item.clientId) && ['VN1A'].includes(item.source) && !item.domestic">
                  <b-img
                    style="height: 16px;"
                    :src="require(`@icons/luggage.svg`)"
                  />
                </template>
                <template v-else>
                  <div v-if="getMinFareOptions(item.fareOptions).luggage !== null">
                    <b-img
                      v-if="getMinFareOptions(item.fareOptions).luggage"
                      style="height: 16px;"
                      :src="require(`@icons/luggage.svg`)"
                    />
                    <b-img
                      v-else
                      style="height: 16px;"
                      :src="require(`@icons/no-luggage.svg`)"
                    />
                  </div>
                </template>
                <span
                  v-b-tooltip.hover.html.v-primary.top.window
                  :title="getMinFareOptions(item.fareOptions).groupClassName"
                >
                  {{ getMinFareOptions(item.fareOptions).bookingClassAndSeatAvail }}
                </span>
              </div>

              <!-- ANCHOR - DownIcon -->
              <div class="fw-600 ml-50">
                <feather-icon
                  v-if="!visible"
                  icon="ChevronDownIcon"
                  size="17"
                />
                <feather-icon
                  v-else
                  icon="ChevronUpIcon"
                  size="17"
                />
              </div>
            </div>
          </div>
        </b-col>

        <!-- ANCHOR Giá -->
        <!-- <b-col class="align-items-center d-flex justify-content-center w-auto px-0">
          <span
            class="text-warning font-weight-bolder"
          >
            {{ item.fareOptions[0].totalAdult !== 0 ? formatCurrency(item.fareOptions[0].totalAdult) : formatCurrency(item.fareOptions[0].totalChild) }}
          </span>
        </b-col> -->
      </b-row>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BImg,
  BBadge,
  BCardText,
  BRow,
  BCol,
  VBTooltip,
  BPopover,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'

import store from '@/store'

import {
  convertISODateTime,
  toHoursAndMinutes,
  formatCurrency,
  resolveAirlineFlightNumber,
} from '@core/utils/filter'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

const optionStop = [
  { text: 'direct', value: 0 },
  { text: '1_transits', value: 1 },
  { text: '2_transits', value: 2 },
]
export default {
  components: {
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BCollapse,
    BBadge,
    BImg,
    BRow,
    BCol,
    BPopover,
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    selectedTrip: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
      optionStop,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    // if (this.item) {
    //   this.item.fareOptions.forEach(element => {
    //     // eslint-disable-next-line no-console, no-multi-assign
    //     this.countTickets = this.countTickets += element.seatAvailable
    //     this.classBooking = ` ${this.classBooking} ${element.bookingClass}`
    //     this.fareType = ` ${this.fareType === '' ? `${this.fareType}` : `${this.fareType},`} ${element.fareType}`
    //   })
    // }
    this.visible = this.isVisible
  },

  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    getAirCraftByIata(code) {
      return this.$store.getters['globalConfig/getAirCraftByIata'](code)
    },
    getAirlineNameByCode(code) {
      return this.$store.getters['globalConfig/getAirlineNameByCode'](code)
    },
  },
  setup(props, { emit }) {
    const {
      getAirportByAirportCode,
    } = useReservationDetailBookingHandle()

    const resolveBadgeSource = (source, contentSource) => {
      if (['GDS', 'NDC'].includes(contentSource)) return `logo-${source} logo-${contentSource}`
      if (['F1', 'AK', 'TH'].includes(source)) return `logo-${source}`
      return ''
    }

    function convertShortenAircraft(aircraftCode) {
      const fullName = store.getters['globalConfig/getAirCraftByIata'](aircraftCode)
      const firstLetter = fullName[0]
      const azRegex = /^[a-zA-Z]*$/
      if (azRegex.test(firstLetter) && !azRegex.test(aircraftCode[0])) {
        return `${firstLetter}${aircraftCode}`
      }
      return aircraftCode
    }

    function getTripNameBySegments(item) {
      const departure = item.departure.IATACode
      const arrival = item.arrival.IATACode
      const stopPoints = item.stopPoint ? item.stopPoint.split(' | ') : []
      const segments = [departure, ...stopPoints, arrival]
      const tripArr = segments.map((pointItem, pointIndex) => {
        let changeAirport = ''
        if (pointIndex !== 0 && pointIndex !== (segments.length - 1)) {
          const index = item?.segments.findIndex(seg => seg?.arrival.IATACode === pointItem)
          const nextArrival = item?.segments[index + 1]?.departure.IATACode
          if (nextArrival !== pointItem) {
            changeAirport = nextArrival
          }
        }
        const res = {
          iataCode: `${pointItem}${changeAirport ? ` (${changeAirport})` : ''}`,
          name: getAirportByAirportCode(pointItem)?.name,
          transitTime: item?.segments.find(seg => seg?.arrival.IATACode === pointItem)?.transitTime,
        }
        return res
      })
      return tripArr
    }

    return {
      convertShortenAircraft,
      resolveBadgeSource,
      resolveAirlineFlightNumber,
      convertISODateTime,
      toHoursAndMinutes,
      formatCurrency,
      getTripNameBySegments,

    }
  },
}
</script>

<style lang="scss" scoped>
.airlines-logo {
  min-width: 80px !important;
}
</style>
